<template>
  <div class="date-time">
    {{ date | dateFormat('d LLL yyyy') }}
    <div
      v-if="!hideTime"
      class="small text-muted"
    >
      {{ date | dateFormat('HH:mm') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DateTime',

  props: {
    date: {
      type: Date,
      default: new Date()
    },

    hideTime: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>

</style>