<template>
  <div class="text-with-subtitle">
    {{ title }}
    <div
      v-if="subtitle"
      class="small text-muted"
    >
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextWithSubtitle',

  props: {
    title: {
      type: String,
      default: ''
    },
    
    subtitle: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
