<template>
  <div class="smart-table">
    <div class="card card-table">
      <div class="card-body">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th
                v-for="item in header"
                :key="item.data"
                :class="{
                  'text-right': item.type === 'money' || item.type === 'number'
                }"
                v-bind="item.attributes || {}"
              >
                {{ item.data }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in rows"
              :key="row.id"
              @click="onRowClicked(row)"
            >
              <td
                v-for="(column, i) in row.columns"
                :key="i"
                :class="{
                  'text-right': column.type === 'money' || column.type === 'number'
                }"
              >
                <template v-if="column.type === 'link'">
                  <router-link :to="column.data.to">
                    {{ column.data.text }}
                  </router-link>
                </template>

                <template v-else-if="column.type === 'datetime'">
                  <date-time :date="column.data" />
                </template>

                <template v-else-if="column.type === 'money'">
                  <number :amount="column.data" prepend-text=" ฿" />
                </template>

                <template v-else-if="column.type === 'number'">
                  <number :amount="column.data" />
                </template>

                <template v-else-if="column.type === 'paymentMethod'">
                  <payment-method :method="column.data" />
                </template>

                <template v-else-if="column.type === 'transactionStatus'">
                  <transaction-status :status="column.data" />
                </template>

                <template v-else-if="column.type === 'subscriptionStatus'">
                  <subscription-status :status="column.data" />
                </template>

                <template v-else-if="column.type === 'textWithSubtitle'">
                  <text-with-subtitle v-bind="column.data" />
                </template>

                <template v-else>
                  <slot :name="column.field">
                    {{ column.data }}
                  </slot>
                </template>
              </td>
            </tr>

            <tr v-if="isEmpty">
              <td :colspan="header.length" class="text-center text-muted">No items found.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DateTime from './DateTime.vue'
import Number from './Number.vue'
import PaymentMethod from './PaymentMethod.vue'
import SubscriptionStatus from './SubscriptionStatus.vue'
import TextWithSubtitle from './TextWithSubtitle.vue'
import TransactionStatus from './TransactionStatus.vue'

export default {
  name: 'SmartTable',

  components: {
    DateTime,
    Number,
    PaymentMethod,
    SubscriptionStatus,
    TransactionStatus,
    TextWithSubtitle
  },

  props: {
    items: {
      type: Array,
      default: () => ([])
    },

    header: {
      type: Array,
      default: () => ([])
    },

    clickableRow: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isEmpty () {
      return this.items.length === 0
    },

    rows () {
      return this.items.map(item => {
        const columns = this.header.map(head => {
          return {
            ...head,
            data: item[head.field]
          }
        })
        columns.id = item.id
        return {
          id: item.id,
          columns
        }
      })
    }
  },

  methods: {
    onRowClicked (row) {
      if (this.clickableRow) {
        this.$emit('row-clicked', row)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
