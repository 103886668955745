<template>
  <div class="subscription-status">
    <span
      class="font-weight-500"
      :class="className"
    >
      {{ text }}
    </span>
  </div>
</template>

<script>
import {
  SUBSCRIPTION_STATUS_WAIT_PAYMENT,
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_CANCELED
} from '../constant.js'

export default {
  name: 'SubscriptionStatus',

  props: {
    status: {
      type: Number,
      required: true
    },

    pill: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    text () {
      switch (this.status) {
        case SUBSCRIPTION_STATUS_WAIT_PAYMENT:
          return 'Wait for first payment'
        case SUBSCRIPTION_STATUS_ACTIVE:
          return 'Active'
        case SUBSCRIPTION_STATUS_CANCELED:
          return 'Canceled'
      }
      return this.status
    },

    className () {
      const classes = []

      if (this.pill) {
        const classMapping = {
          [SUBSCRIPTION_STATUS_WAIT_PAYMENT]: 'badge badge-pill badge-warning',
          [SUBSCRIPTION_STATUS_ACTIVE]: 'badge badge-pill badge-success',
          [SUBSCRIPTION_STATUS_CANCELED]: 'badge badge-pill badge-secondary'
        }

        if (classMapping[this.status]) {
          classes.push(classMapping[this.status])
        }
      }
      else {
        const classMapping = {
          [SUBSCRIPTION_STATUS_WAIT_PAYMENT]: 'text-warning',
          [SUBSCRIPTION_STATUS_ACTIVE]: 'text-success',
          [SUBSCRIPTION_STATUS_CANCELED]: 'text-secondary'
        }

        if (classMapping[this.status]) {
          classes.push(classMapping[this.status])
        }
      }

      return classes.join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
