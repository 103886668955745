<template>
  <div class="invoice-list-page">
    <h2>Invoices</h2>
    <div class="d-flex justify-content-between align-items-center py-3">
      <form method="get">
        <div class="input-group input-group-search">
          <input type="text" name="keyword" class="form-control" placeholder="Search invoice...">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="submit">
              <b-icon-search />
            </button>
          </div>
        </div>
      </form>
    </div>

    <smart-table
      :header="header"
      :items="items"
      clickable-row
      @row-clicked="onRowClicked"
    />
  </div>
</template>

<script>
import SmartTable from '../components/SmartTable.vue'

export default {
  name: 'InvoiceList',

  components: {
    SmartTable
  },

  computed: {
    items () {
      const items = this.data ? this.data.data : []
      return items.map(item => {
        return {
          ...item,
          created_at: new Date(item.created_at),
          donor: {
            title: `${item.customer_first_name} ${item.customer_last_name}`,
            subtitle: item.customer_email
          }
        }
      })
    }
  },

  data () {
    return {
      header: [
        { data: 'Created', field: 'created_at', type: 'datetime' },
        { data: 'Donor', field: 'donor', type: 'textWithSubtitle' },
        { data: 'Amount', field: 'price', type: 'money' },
        { data: 'Payment Method', field: 'payment_method', type: 'paymentMethod' },
        { data: 'Status', field: 'status', type: 'transactionStatus' },
        { data: 'Order ID', field: 'order_id' }
      ],

      data: null
    }
  },

  mounted () {
    this.fetch()
  },

  methods: {
    async fetch () {
      this.loading = true
      try {
        const resp = await this.$invoices.list()
        this.data = resp.data
      }
      catch (e) {
        console.error(e.message)
      }
    },

    onRowClicked (item) {
      this.$router.push({
        name: 'invoice-edit',
        params: {
          id: item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
