<template>
  <div class="payment-method text-center">
    <img
      v-if="imageSrc"
      :src="imageSrc"
      :alt="altText"
      height="20"
    >
    <div class="mt-1">{{ text }}</div>
  </div>
</template>

<script>
import {
  PAYMENT_METHOD_BANK_TRANSFER,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_INTERNET_BANKING,
  PAYMENT_METHOD_LINE_PAY
} from '../constant.js'

export default {
  name: 'PaymentMethod',

  props: {
    method: {
      type: String,
      required: true
    }
  },

  computed: {
    text () {
      switch (this.method) {
        case PAYMENT_METHOD_CREDIT_CARD:
          return 'Credit Card'
        case PAYMENT_METHOD_BANK_TRANSFER:
          return 'Bank Transfer'
        case PAYMENT_METHOD_LINE_PAY:
          return 'LINE Pay'
        case PAYMENT_METHOD_INTERNET_BANKING:
          return 'Internet Banking'
      }
      return this.method
    },

    imageSrc () {
      switch (this.method) {
        case PAYMENT_METHOD_CREDIT_CARD:
          return null
        case PAYMENT_METHOD_BANK_TRANSFER:
          return null
        case PAYMENT_METHOD_LINE_PAY:
          return '/img/payment_method_rabbit_linepay.png'
        case PAYMENT_METHOD_INTERNET_BANKING:
          return null
      }
      return null
    },

    altText () {
      return this.text
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
