/**
 * Payment method.
 */
const PAYMENT_METHOD_CREDIT_CARD = 'credit_card'
const PAYMENT_METHOD_BANK_TRANSFER = 'bank_transfer'
const PAYMENT_METHOD_LINE_PAY = 'line_pay'
const PAYMENT_METHOD_INTERNET_BANKING = 'internet_banking'

/**
 * Transaction status.
 */
const TRANSACTION_STATUS_WAIT_PAYMENT = 0
const TRANSACTION_STATUS_WAIT_APPROVAL = 1
const TRANSACTION_STATUS_PAID = 2
const TRANSACTION_STATUS_CANCELED = 3
const TRANSACTION_STATUS_FAILED = 999

/**
 * Subscription status.
 */
const SUBSCRIPTION_STATUS_WAIT_PAYMENT = 0
const SUBSCRIPTION_STATUS_ACTIVE = 1
const SUBSCRIPTION_STATUS_CANCELED = 2

export {
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_BANK_TRANSFER,
  PAYMENT_METHOD_LINE_PAY,
  PAYMENT_METHOD_INTERNET_BANKING,

  TRANSACTION_STATUS_WAIT_PAYMENT,
  TRANSACTION_STATUS_WAIT_APPROVAL,
  TRANSACTION_STATUS_PAID,
  TRANSACTION_STATUS_CANCELED,
  TRANSACTION_STATUS_FAILED,

  SUBSCRIPTION_STATUS_WAIT_PAYMENT,
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_CANCELED
}
