<template>
  <div class="transaction-status">
    <span
      class="font-weight-500"
      :class="className"
    >
      {{ text }}
    </span>
  </div>
</template>

<script>
import {
  TRANSACTION_STATUS_CANCELED,
  TRANSACTION_STATUS_FAILED,
  TRANSACTION_STATUS_PAID,
  TRANSACTION_STATUS_WAIT_APPROVAL,
  TRANSACTION_STATUS_WAIT_PAYMENT
} from '../constant'

export default {
  name: 'TransactionStatus',

  props: {
    status: {
      type: Number,
      required: true
    },

    pill: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    text () {
      switch (this.status) {
        case TRANSACTION_STATUS_WAIT_PAYMENT:
          return 'Wait for payment'
        case TRANSACTION_STATUS_WAIT_APPROVAL:
          return 'Wait for approval'
        case TRANSACTION_STATUS_PAID:
          return 'Paid'
        case TRANSACTION_STATUS_CANCELED:
          return 'Canceled'
        case TRANSACTION_STATUS_FAILED:
          return 'Failed'
      }
      return this.status
    },

    className () {
      const classes = []

      if (this.pill) {
        const classMapping = {
          [TRANSACTION_STATUS_WAIT_PAYMENT]: 'badge badge-pill badge-dark',
          [TRANSACTION_STATUS_WAIT_APPROVAL]: 'badge badge-pill badge-warning',
          [TRANSACTION_STATUS_PAID]: 'badge badge-pill badge-success',
          [TRANSACTION_STATUS_CANCELED]: 'badge badge-pill badge-secondary',
          [TRANSACTION_STATUS_FAILED]: 'badge badge-pill badge-secondary'
        }

        if (classMapping[this.status]) {
          classes.push(classMapping[this.status])
        }
      }
      else {
        const classMapping = {
          [TRANSACTION_STATUS_WAIT_APPROVAL]: 'text-warning',
          [TRANSACTION_STATUS_PAID]: 'text-success',
          [TRANSACTION_STATUS_CANCELED]: 'text-secondary',
          [TRANSACTION_STATUS_FAILED]: 'text-secondary'
        }

        if (classMapping[this.status]) {
          classes.push(classMapping[this.status])
        }
      }

      return classes.join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
